"use client";

import { Character } from "@/app/character";
import { useCharacterRedo } from "@/hooks/useCharacterRedo";
import { createContext, useContext, useMemo } from "react";

interface CharacterRedoContextType {
	redoCharacter: (redoType: string) => Promise<void>;
	charactersLength: number;
	previousCharacter: () => void;
	nextCharacter: () => void;
	currentCharacterIndex: number;
	addToCharacters: (object: string, texture: string) => void;
	removeFromCharacters: () => void;
	clearCharacters: () => void;
}

const CharacterRedoContext = createContext<
	CharacterRedoContextType | undefined
>(undefined);

export const CharacterRedoProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const characterRedo = useCharacterRedo();
	return (
		<CharacterRedoContext.Provider value={characterRedo}>
			{children}
		</CharacterRedoContext.Provider>
	);
};

export const useCharacterRedoContext = () => {
	const context = useContext(CharacterRedoContext);
	if (context === undefined) {
		throw new Error(
			"useCharacterRedoContext must be used within a CharacterRedoProvider"
		);
	}
	return context;
};
