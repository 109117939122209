"use client";

import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from "react";
import axios from "axios";
import { useWebSocketContext } from "./WebSocketProvider";

interface JobContextType {
	currentJobId: string | null;
	jobStatus: any | null;
	fetchInitialJob: () => Promise<void>;
	clearJob: () => void;
}

const JobContext = createContext<JobContextType | undefined>(undefined);

export const JobProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [currentJobId, setCurrentJobId] = useState<string | null>(null);
	const [jobStatus, setJobStatus] = useState<any | null>(null);
	const { status: connectionStatus, setJobId } = useWebSocketContext();
	const [hasInitialized, setHasInitialized] = useState(false);

	const fetchJobStatus = useCallback(async (jobId: string) => {
		try {
			const response = await axios.get(`/api/v1/job/${jobId}/status`);
			return response.data;
		} catch (error) {
			console.error("Error fetching job status:", error);
			return null;
		}
	}, []);

	const fetchInitialJob = useCallback(async () => {
		if (hasInitialized) return;

		const storedJobId = localStorage.getItem("job_id");
		if (storedJobId) {
			const status = await fetchJobStatus(storedJobId);
			if (status) {
				console.log("Setting job status:", status);
				setCurrentJobId(storedJobId);
				setJobStatus(status);
				if (
					status.type === "job_status" &&
					status.data.status === "processing"
				) {
					console.log("Setting job id in websocket:", storedJobId);
					setJobId(storedJobId);
				}
			} else {
				localStorage.removeItem("job_id");
			}
		}
		setHasInitialized(true);
	}, [fetchJobStatus, setJobId, hasInitialized]);
	useEffect(() => {
		const fetchJob = async () => {
			await fetchInitialJob();
		};
		fetchJob();
	}, [fetchInitialJob]);

	const clearJob = useCallback(() => {
		setCurrentJobId(null);
		setJobStatus(null);
		localStorage.removeItem("job_id");
	}, []);

	return (
		<JobContext.Provider
			value={{ currentJobId, jobStatus, fetchInitialJob, clearJob }}
		>
			{children}
		</JobContext.Provider>
	);
};

export const useJob = () => {
	const context = useContext(JobContext);
	if (context === undefined) {
		throw new Error("useJob must be used within a JobProvider");
	}
	return context;
};
