"use client";
import React, { createContext, useContext, useMemo } from "react";
import {
	useAnimationCreation,
	AnimationCreationStep,
	VideoSource,
} from "@/hooks/useAnimationCreation";
interface AnimationCreationContextType {
	step: AnimationCreationStep;
	setStep: (step: AnimationCreationStep) => void;
	videoSource: VideoSource | null;
	setVideoSource: React.Dispatch<React.SetStateAction<VideoSource | null>>;
	previewVideoUpload: (file: File) => void;
	cancelCreation: () => void;
	startMotionGeneration: () => Promise<void>;
	progress: number;
}

const AnimationCreationContext = createContext<
	AnimationCreationContextType | undefined
>(undefined);

export const AnimationCreationProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const animationCreation = useAnimationCreation();
	const value = useMemo(() => animationCreation, [animationCreation]);

	return (
		<AnimationCreationContext.Provider value={value}>
			{children}
		</AnimationCreationContext.Provider>
	);
};

export const useAnimationCreationContext = () => {
	const context = useContext(AnimationCreationContext);
	if (context === undefined) {
		throw new Error(
			"useAnimationCreationContext must be used within an AnimationCreationProvider"
		);
	}
	return context;
};
