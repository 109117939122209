"use client";

import { useWebSocket } from "@/hooks/useWebSocket";
import React, { createContext, useContext } from "react";

export type WebSocketStatus = "initializing" | "connected" | "disconnected" | "reconnecting" | "completed";

interface WebSocketContextType {
	status: WebSocketStatus;
	// sendMessage: (message: string) => void;
	lastMessage: Record<string, any> | null;
	setJobId: (jobId: string | null) => void;
	close: () => void;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(
	undefined
);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const websocket = useWebSocket();
	return (
		<WebSocketContext.Provider value={websocket}>
			{children}
		</WebSocketContext.Provider>
	);
};

export const useWebSocketContext = () => {
	const context = useContext(WebSocketContext);
	if (context === undefined) {
		throw new Error("useWebSocket must be used within a WebSocketProvider");
	}
	return context;
};
