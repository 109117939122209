import { WebSocketStatus } from "@/contexts";
import { useState, useEffect, useCallback, useRef } from "react";

const wsBaseURL = process.env.NEXT_PUBLIC_WS_API_URL || "ws://localhost:8000";

class WebSocketManager {
	private socket: WebSocket | null = null;
	private jobId: string | null = null;
	private onStatusChange: (status: WebSocketStatus) => void;
	private onMessage: (message: any) => void;
	private reconnectAttempts: number = 0;
	private maxReconnectAttempts: number = 5;
	private reconnectInterval: number = 5000;
	private heartbeatInterval: number = 30000; // 30 seconds
	private heartbeatTimer: NodeJS.Timeout | null = null;
	private isJobCompleted: boolean = false;
	private isConnecting: boolean = false;

	constructor(
		onStatusChange: (status: WebSocketStatus) => void,
		onMessage: (message: any) => void
	) {
		this.onStatusChange = onStatusChange;
		this.onMessage = onMessage;
	}

	connect(jobId: string | null) {
		if (
			this.isConnecting ||
			(this.socket && this.socket.readyState === WebSocket.OPEN)
		) {
			console.log("WebSocket is already connected or connecting");
			return;
		}
		if (!jobId) {
			console.error("Invalid job ID provided");
			return;
		}
		if (this.socket) {
			this.socket.close();
		}

		this.jobId = jobId;
		this.isJobCompleted = false;
		this.isConnecting = true;

		this.createWebSocket();
		console.log("Connecting to WebSocket... jobId:", jobId);
	}

	private createWebSocket() {
		this.socket = new WebSocket(`${wsBaseURL}/ws/job/${this.jobId}`);

		this.socket.onopen = () => {
			console.log("WebSocket connected");
			if (!this.jobId) {
				console.error("Invalid job ID provided");
				this.close();
				return;
			}
			this.onStatusChange("connected");
			this.reconnectAttempts = 0;
			this.isConnecting = false;
			this.startHeartbeat();
		};

		this.socket.onmessage = (event) => {
			// console.log("WebSocket message received:", event.data);
			try {
				const parsedData = JSON.parse(event.data);
				if (parsedData.type === "heartbeat") {
					console.log("Heartbeat received");
				} else if (parsedData.type === "job_completed") {
					this.handleJobCompletion(parsedData);
				} else if (parsedData.type !== "connection_close") {
					this.onMessage(parsedData);
				} else {
					console.log("Received connection_close message, closing socket");
					this.close();
				}
			} catch (error) {
				console.error("Error parsing WebSocket message:", error);
			}
		};

		this.socket.onclose = (event) => {
			console.log("WebSocket disconnected", event.code, event.reason);
			this.stopHeartbeat();
			if (!this.isJobCompleted) {
				this.onStatusChange("disconnected");
				this.attemptReconnect();
			}
		};

		this.socket.onerror = (error) => {
			console.error("WebSocket error:", error);
		};
	}

	private handleJobCompletion(data: any) {
		console.log("Job completed:", data);
		this.isJobCompleted = true;
		this.onMessage(data);
		this.onStatusChange("completed");
		this.close();
	}

	private attemptReconnect() {
		if (this.isJobCompleted) {
			console.log("Job is already completed. Not attempting to reconnect.");
			this.jobId = null;
			return;
		}
		if (!this.jobId) {
			console.error("Invalid job ID provided");
			return;
		}

		if (this.reconnectAttempts < this.maxReconnectAttempts) {
			this.reconnectAttempts++;
			this.onStatusChange("reconnecting");
			console.log(
				`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`
			);
			setTimeout(() => this.createWebSocket(), this.reconnectInterval);
		} else {
			console.log(
				"Max reconnection attempts reached. Falling back to polling."
			);
			this.fallbackToPolling();
		}
	}

	private fallbackToPolling() {
		// Implement polling logic here
		const pollInterval = 10000; // Poll every 10 seconds
		const pollJob = () => {
			if (this.isJobCompleted) {
				console.log("Job is completed. Stopping polling.");
				return;
			}

			fetch(`/api/v1/job/${this.jobId}/status`)
				.then((response) => response.json())
				.then((data) => {
					if (data.type === "job_completed") {
						this.handleJobCompletion(data);
					} else {
						this.onMessage(data);
						setTimeout(pollJob, pollInterval);
					}
				})
				.catch((error) => {
					console.error("Error polling job status:", error);
					setTimeout(pollJob, pollInterval);
				});
		};
		pollJob();
	}

	private startHeartbeat() {
		this.heartbeatTimer = setInterval(() => {
			if (this.socket && this.socket.readyState === WebSocket.OPEN) {
				this.socket.send(JSON.stringify({ type: "heartbeat" }));
			}
		}, this.heartbeatInterval);
	}

	private stopHeartbeat() {
		if (this.heartbeatTimer) {
			clearInterval(this.heartbeatTimer);
			this.heartbeatTimer = null;
		}
	}

	close() {
		this.stopHeartbeat();
		if (this.socket) {
			this.socket.close();
			this.socket = null;
		}
		this.jobId = null;
		this.isConnecting = false;
		if (!this.isJobCompleted) {
			this.onStatusChange("disconnected");
		}
	}
}

export const useWebSocket = () => {
	const [status, setStatus] = useState<WebSocketStatus>("initializing");
	const [lastMessage, setLastMessage] = useState<Record<string, any> | null>(
		null
	);
	const [isConnecting, setIsConnecting] = useState(false);

	const managerRef = useRef<WebSocketManager | null>(null);

	const setJobId = useCallback(
		(jobId: string | null) => {
			if (jobId && managerRef.current && !isConnecting) {
				setIsConnecting(true);
				managerRef.current.connect(jobId);
			}
		},
		[isConnecting]
	);

	useEffect(() => {
		managerRef.current = new WebSocketManager(
			(newStatus) => {
				setStatus(newStatus);
				if (newStatus === "connected" || newStatus === "disconnected") {
					setIsConnecting(false);
				}
			},
			(message) => setLastMessage(message)
		);

		return () => {
			if (managerRef.current) {
				managerRef.current.close();
			}
			setIsConnecting(false);
		};
	}, []);

	const close = useCallback(() => {
		if (managerRef.current) {
			console.log("Closing WebSocket connection");
			managerRef.current.close();
			setIsConnecting(false);
			setLastMessage(null);
		}
	}, []);

	return {
		status,
		lastMessage,
		setJobId,
		close,
	};
};
