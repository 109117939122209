"use client";

import { OrthographicCamera, PerspectiveCamera, Mesh } from "three";
import { createContext, useContext, useState } from "react";

type Dimensions = {
	width: number;
	height: number;
};
export type ThreeElements = {
	camera: OrthographicCamera | PerspectiveCamera;
	mesh: Mesh;
};
interface ICharacterPreviewContext {
	threeElements: ThreeElements;
	setThreeElements: (threeElements: ThreeElements) => void;
	dimensions: Dimensions;
	setDimensions: React.Dispatch<React.SetStateAction<Dimensions>>;
}

const CharacterPreviewContext = createContext<ICharacterPreviewContext>({
	threeElements: {
		camera: new OrthographicCamera(),
		mesh: new Mesh(),
	} as ThreeElements,
	setThreeElements: () => {},
	dimensions: { width: 0, height: 0 },
	setDimensions: () => {},
});

export const CharacterPreviewProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [threeElements, setThreeElements] = useState<ThreeElements>({
		camera: new OrthographicCamera(),
		mesh: new Mesh(),
	});
	const [dimensions, setDimensions] = useState<Dimensions>({
		width: 0,
		height: 0,
	});

	return (
		<CharacterPreviewContext.Provider
			value={{ threeElements, setThreeElements, dimensions, setDimensions }}
		>
			{children}
		</CharacterPreviewContext.Provider>
	);
};

export const useCharacterPreviewContext = () => {
	return useContext(CharacterPreviewContext);
};
