"use client";

import { useState, useCallback, useEffect } from "react";
import { Character } from "../app/character/Character";
import axios, { AxiosError } from "axios";
import { ControlPosition } from "react-draggable";
import { useCharacter } from "@/contexts/CharacterContext";
import { useWebSocketContext } from "@/contexts/WebSocketProvider";
import { makeToastError } from "@/utils";
import { CharacterProcessStep, useJob } from "@/contexts";
import { useAuth } from "@/components/Auth";
import { set } from "date-fns";

export enum CharacterCreationStep {
	"initial",
	"upload",
	"processing",
	"previewMesh",
	"rigging",
	"previewRigging",
	"motion",
	"error",
	"previewRenders",
}

export const useCharacterCreation = () => {
	const [step, setStep] = useState<CharacterCreationStep>(
		CharacterCreationStep.initial
	);
	const { setCharacter, character } = useCharacter();
	const [progress, setProgress] = useState<number>(0);
	const [currentJobId, setCurrentJobId] = useState<string | null>(null);
	const [processStep, setProcessStep] = useState<CharacterProcessStep>(
		CharacterProcessStep.modeling
	);
	const [shouldRefreshCredits, setShouldRefreshCredits] = useState(false);
	const [renderQuickRedoImages, setRenderQuickRedoImages] = useState<
		Record<string, string>
	>({});

	const [quickRedoObjects, setQuickRedoObjects] = useState<
		Record<string, string>
	>({});
	const [quickRedoTextures, setQuickRedoTextures] = useState<
		Record<string, string>
	>({});

	const { refreshUserData } = useAuth();

	const {
		status: connectionStatus,
		lastMessage,
		setJobId,
		close,
	} = useWebSocketContext();

	const updateCharacterPreviewRigging = useCallback(
		(newCharacter: Partial<Character>) => {
			console.log("Updating rigging character:", newCharacter);
			setCharacter((prev) => ({
				...prev,
				...newCharacter,
			}));
			setStep(CharacterCreationStep.previewRigging);
		},
		[setCharacter]
	);

	const updateCharacterPreviewMesh = useCallback(
		(newCharacter: Partial<Character>) => {
			console.log("Updating preview character:", newCharacter);
			setCharacter((prev) => ({
				...prev,
				...newCharacter,
			}));
			setStep(CharacterCreationStep.previewMesh);
		},
		[setCharacter]
	);

	useEffect(() => {
		if (shouldRefreshCredits) {
			refreshUserData(true);
			setShouldRefreshCredits(false);
		}
	}, [shouldRefreshCredits, refreshUserData]);

	const {
		currentJobId: initialJobId,
		jobStatus: initialJobStatus,
		clearJob,
	} = useJob();
	const updateQuickRedo = useCallback(
		(
			quickRedoObjects: Record<string, string>,
			quickRedoTextures: Record<string, string>,
			quickRedoImages: Record<string, string>,
			id: string,
			iterationNb: number = 0
		) => {
			setQuickRedoObjects(quickRedoObjects);
			setQuickRedoTextures(quickRedoTextures);
			setRenderQuickRedoImages(quickRedoImages);
			setStep(CharacterCreationStep.previewRenders);
			setCharacter((prev) => ({
				...prev,
				id: id,
				isReadyForAnimation: false,
				iterationNb: iterationNb,
				object: Object.values(quickRedoObjects)[0],
				objectTexture: Object.values(quickRedoTextures)[0],
			}));
		},
		[setCharacter]
	);

	const handleJobStatus = useCallback(
		(data: any) => {
			if (data.type === "modeling" || data.type === "rigging") {
				if (data.status === "failed") {
					makeToastError({
						message: `Error: ${data.result.error}`,
						returnButton: true,
					});
					console.error("Job failed:", data.result.error);
					close();
					setCurrentJobId(null);
					setProgress(0);
					if (data.type === "rigging")
						updateCharacterPreviewMesh({
							iterationNb: data.result.iteration_nb,
							object: data.result.final_obj_url,
							objectTexture: data.result.final_texture_url,
							id: data.result.id,
						});
					setStep(
						data.type === "modeling"
							? CharacterCreationStep.upload
							: CharacterCreationStep.rigging
					);
				} else if (data.status === "processing") {
					setProgress(data.progress || 0);
					setStep(CharacterCreationStep.processing);
					setProcessStep(
						data.type === "modeling"
							? CharacterProcessStep.modeling
							: CharacterProcessStep.rigging
					);
				} else if (data.status === "completed") {
					if (
						data.type === "modeling" &&
						data.result.final_obj_url &&
						data.result.final_texture_url
					) {
						updateQuickRedo(
							data.result.final_obj_url,
							data.result.final_texture_url,
							data.result.render_urls,
							data.result.id
						);
						// updateCharacterPreviewMesh({
						// 	iterationNb: 0,
						// 	object: data.result.final_obj_url,
						// 	objectTexture: data.result.final_texture_url,
						// 	id: data.result.id,
						// 	isReadyForAnimation: false,
						// });
					} else if (
						data.type === "rigging" &&
						data.result.rigged_character_url &&
						data.result.jumping_character_url
					) {
						updateCharacterPreviewRigging({
							riggedObject: data.result.rigged_character_url,
							jumpingCharacter: data.result.jumping_character_url,
							objectTexture: data.result.final_texture_url,
							isReadyForAnimation: true,
							id: data.result.id,
						});
					}
					setCurrentJobId(null);
					setProgress(0);
					close();
				}
			} else if (data.type === "redo_modeling") {
				if (data.status === "processing") {
					setProgress(data.progress || 0);
					setProcessStep(CharacterProcessStep.redo);
					setStep(CharacterCreationStep.processing);
				}
			}
		},
		[
			close,
			updateCharacterPreviewMesh,
			updateCharacterPreviewRigging,
			updateQuickRedo,
		]
	);

	useEffect(() => {
		if (
			initialJobId &&
			initialJobStatus &&
			(initialJobStatus.data.type === "modeling" ||
				initialJobStatus.data.type === "rigging" ||
				initialJobStatus.data.type === "redo_modeling")
		) {
			if (initialJobStatus.data.type === "redo_modeling") {
				setStep(CharacterCreationStep.previewRenders);
			} else {
				setCurrentJobId(initialJobId);
				handleJobStatus(initialJobStatus.data);
			}
		}
	}, [handleJobStatus, initialJobId, initialJobStatus]);

	useEffect(() => {
		if (currentJobId) {
			console.log(
				"Setting job id in websocket useCharacterCreation:",
				currentJobId
			);
			setJobId(currentJobId);
		}
	}, [currentJobId, setJobId]);

	useEffect(() => {
		if (
			lastMessage &&
			lastMessage.type === "job_status" &&
			lastMessage.data.type === "modeling"
		) {
			const data = lastMessage.data;
			if (data.status === "failed") {
				makeToastError({
					message: `Error: ${data.result.error}`,
					returnButton: true,
				});
				console.error("Job failed:", data.result.error);
				setStep(CharacterCreationStep.upload);
				close();
				setCurrentJobId(null);
				console.log("Setting progress to 0 in modeling");
				setProgress(0);
			} else if (data.status === "processing") {
				setProgress(data.progress || 0);
			}
		} else if (
			lastMessage &&
			lastMessage.type === "job_completed" &&
			lastMessage.data.type === "modeling"
		) {
			const data = lastMessage.data;
			console.log(`Character Creation ${data.type} Job status:`, data.status);
			if (data.status === "completed") {
				console.log(`Character Creation ${data.type} Job result:`, data.result);

				if (
					data.result.final_obj_url &&
					data.result.final_texture_url &&
					data.result.render_urls
				) {
					updateQuickRedo(
						data.result.final_obj_url,
						data.result.final_texture_url,
						data.result.render_urls,
						data.result.id
					);
					setShouldRefreshCredits(true);
					// updateCharacterPreviewMesh({
					// 	iterationNb: 0,
					// 	object: data.result.final_obj_url,
					// 	objectTexture: data.result.final_texture_url,
					// 	id: data.result.id,
					// 	isReadyForAnimation: false,
					// });
				}

				setCurrentJobId(null);
				console.log("Setting progress to 0 in modeling");
				setProgress(0);
				close();
			}
		}
	}, [close, lastMessage, updateQuickRedo]);

	useEffect(() => {
		if (
			lastMessage &&
			lastMessage.type === "job_status" &&
			lastMessage.data.type === "rigging"
		) {
			const data = lastMessage.data;
			if (data.status === "failed") {
				makeToastError({
					message: `Error: ${data.result.error}`,
					returnButton: true,
				});
				console.error("Rigging Job failed:", data.result.error);
				close();
				setCurrentJobId(null);
				console.log("Setting progress to 0 in rigging");
				setProgress(0);
				updateCharacterPreviewMesh({
					iterationNb: data.result.iteration_nb,
					object: data.result.final_obj_url,
					objectTexture: data.result.final_texture_url,
					id: data.result.id,
				});
				setStep(CharacterCreationStep.rigging);
			} else if (data.status === "processing") {
				setProgress(data.progress || 0);
			}
		} else if (
			lastMessage &&
			lastMessage.type === "job_completed" &&
			lastMessage.data.type === "rigging"
		) {
			const data = lastMessage.data;
			console.log(`Character Creation ${data.type} Job status:`, data.status);

			if (data.status === "completed") {
				console.log(`Character Creation ${data.type} Job result:`, data.result);
				if (
					data.result.rigged_character_url &&
					data.result.jumping_character_url
				) {
					updateCharacterPreviewRigging({
						riggedObject: data.result.rigged_character_url,
						jumpingCharacter: data.result.jumping_character_url,
						isReadyForAnimation: true,
						objectTexture: data.result.final_texture_url,
					});
				}

				setCurrentJobId(null);
				console.log("Setting progress to 0 in rigging");
				setProgress(0);
				close();
			}
		}
	}, [
		close,
		lastMessage,
		updateCharacterPreviewMesh,
		updateCharacterPreviewRigging,
	]);

	const confirmQuickRedo = useCallback(
		async (quickRedoType: string) => {
			const formData = new FormData();
			formData.append("character", character?.id ?? "");
			formData.append("redo_type", quickRedoType);
			formData.append("iteration_nb", (character?.iterationNb ?? 0).toString());

			try {
				const response = await fetch("/api/v1/confirm-quick-redo-type", {
					method: "POST",
					body: formData,
				});

				if (response.ok) {
					setStep(CharacterCreationStep.previewMesh);
					updateCharacterPreviewMesh({
						iterationNb: character?.iterationNb ?? 0,
						object: quickRedoObjects[quickRedoType],
						objectTexture: quickRedoTextures[quickRedoType],
						isReadyForAnimation: false,
					});
				}
			} catch (error) {
				console.error("Error:", error);
				makeToastError({
					message: `Error confirming character: ${
						error instanceof Error ? error.message : "Unknown error"
					}`,
					duration: 5000,
				});
			}
		},
		[
			character?.id,
			character?.iterationNb,
			quickRedoObjects,
			quickRedoTextures,
			updateCharacterPreviewMesh,
		]
	);

	const startGeneration = useCallback(async () => {
		setStep(CharacterCreationStep.processing);
		setProgress(0);
		try {
			if (!character?.file) {
				throw new Error("No file to upload");
			}
			const formData = new FormData();
			formData.append("file", character.file, character.file.name);

			const response = await axios.post(
				"/api/v1/character-modeling",
				formData,
				{
					headers: { "Content-Type": "multipart/form-data" },
				}
			);

			if (response.data.job_id) {
				setCurrentJobId(response.data.job_id);
				setProcessStep(CharacterProcessStep.modeling);
				// Set job id in local storage
				localStorage.setItem("job_id", response.data.job_id);
			} else {
				throw new Error("No job ID received from server");
			}
		} catch (error) {
			console.error("Error uploading image:", error);
			if (error instanceof AxiosError && error.status === 403) {
				makeToastError({
					message: `Error: ${error.response?.data.detail}`,
					returnButton: true,
				});
			}
			setStep(CharacterCreationStep.upload);
		}
	}, [character?.file, setProcessStep]);

	const rigCharacter = useCallback(
		async (rigData: { [key: string]: ControlPosition }) => {
			setStep(CharacterCreationStep.processing);
			setProgress(0);
			setProcessStep(CharacterProcessStep.rigging);
			const fullRigData = { ...rigData, symmetric: true, "skeleton-lod": "" };

			try {
				const response = await axios.post(
					"/api/v1/character-rigging",
					{
						character_id: character?.id,
						rigging_inputs: fullRigData,
						iteration_nb: character?.iterationNb,
					},
					{
						headers: { "Content-Type": "application/json" },
					}
				);

				if (response.data.job_id) {
					setCurrentJobId(response.data.job_id);
					localStorage.setItem("job_id", response.data.job_id);
				} else {
					throw new Error("No job ID received from server");
				}
			} catch (error) {
				console.error("Error starting character rigging:", error);
				setStep(CharacterCreationStep.rigging);
			}
		},
		[character?.id, character?.iterationNb, setProcessStep]
	);

	const previewImageUpload = useCallback(
		(file: File) => {
			const localImageUrl = URL.createObjectURL(file);
			setCharacter((prev) => ({
				...prev,
				image: localImageUrl,
				file: file,
			}));
			setStep(CharacterCreationStep.upload);
		},
		[setCharacter]
	);
	const startRigCharacter = useCallback(() => {
		setStep(CharacterCreationStep.rigging);
	}, []);

	const cancelCreation = useCallback(() => {
		setCharacter(null);
		setJobId(null);
		setProgress(0);
		close();
		clearJob();
		setStep(CharacterCreationStep.initial);
		setProcessStep(CharacterProcessStep.modeling);
	}, [clearJob, close, setCharacter, setJobId]);

	const getQuickRedoData = useCallback(
		(redoType?: string) => {
			if (!redoType) {
				return {
					object: Object.values(quickRedoObjects)[0],
					texture: Object.values(quickRedoTextures)[0],
				};
			}
			return {
				object: quickRedoObjects[redoType],
				texture: quickRedoTextures[redoType],
			};
		},
		[quickRedoObjects, quickRedoTextures]
	);

	return {
		step,
		character,
		previewImageUpload,
		startGeneration,
		rigCharacter,
		cancelCreation,
		setStep,
		startRigCharacter,
		progress,
		connectionStatus,
		processStep,
		setProcessStep,
		setProgress,
		renderQuickRedoImages,
		confirmQuickRedo,
		setRenderQuickRedoImages,
		setQuickRedoObjects,
		setQuickRedoTextures,
		updateQuickRedo,
		getQuickRedoData,
	};
};
