"use client";

import { Character } from "@/app/character";
import { useAuth } from "@/components/Auth";
import React, { createContext, useContext, useEffect, useState } from "react";

interface CharacterContextType {
	character: Character | null;
	setCharacter: React.Dispatch<React.SetStateAction<Character | null>>;
}

const CharacterContext = createContext<CharacterContextType | undefined>(
	undefined
);

export const CharacterProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [character, setCharacter] = useState<Character | null>(null);

	// useEffect(() => {
	// 	const handleCharacterUpdate = async () => {
	// 		if (character) {
	// 			console.log("Character updated, refreshing credits...");
	// 			await refreshUserData(false);
	// 		}
	// 	};
	// 	handleCharacterUpdate();
	// }, [character, refreshUserData]);

	// FEATURE DISABLED: Free generations
	// useEffect(() => {
	// 	const handleCharacterUpdate = async () => {
	// 		if (character) {
	// 			console.log("Character updated, refreshing free generation count...");
	// 			await refreshFreeGenerationCount();
	// 		}
	// 	};
	// 	if (!isAuthenticated) {
	// 		handleCharacterUpdate();
	// 	}
	// }, [character, isAuthenticated, refreshFreeGenerationCount]);

	return (
		<CharacterContext.Provider value={{ character, setCharacter }}>
			{children}
		</CharacterContext.Provider>
	);
};

export const useCharacter = () => {
	const context = useContext(CharacterContext);
	if (context === undefined) {
		throw new Error("useCharacter must be used within a CharacterProvider");
	}
	return context;
};
