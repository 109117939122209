"use client";

import React, { createContext, useContext } from "react";
import {
	useCharacterCreation,
	CharacterCreationStep,
} from "../hooks/useCharacterCreation";
import { ControlPosition } from "react-draggable";
import { WebSocketStatus } from "./WebSocketProvider";

export enum CharacterProcessStep {
	"modeling",
	"rigging",
	"redo",
}

interface CharacterCreationContextType {
	step: CharacterCreationStep;
	// character: Character;
	previewImageUpload: (file: File) => void;
	startGeneration: () => Promise<void>;
	startRigCharacter: () => void;
	rigCharacter: (rigData: { [key: string]: ControlPosition }) => Promise<void>;
	cancelCreation: () => void;
	setStep: (step: CharacterCreationStep) => void;
	progress: number;
	setProgress: React.Dispatch<React.SetStateAction<number>>;
	connectionStatus: WebSocketStatus;
	processStep: CharacterProcessStep;
	setProcessStep: React.Dispatch<React.SetStateAction<CharacterProcessStep>>;
	renderQuickRedoImages: Record<string, string>;
	setRenderQuickRedoImages: React.Dispatch<
		React.SetStateAction<Record<string, string>>
	>;
	confirmQuickRedo: (key: string) => Promise<void>;
	setQuickRedoObjects: React.Dispatch<
		React.SetStateAction<Record<string, string>>
	>;
	setQuickRedoTextures: React.Dispatch<
		React.SetStateAction<Record<string, string>>
	>;
	updateQuickRedo: (
		quickRedoObjects: Record<string, string>,
		quickRedoTextures: Record<string, string>,
		quickRedoImages: Record<string, string>,
		id: string,
		iterationNb: number
	) => void;
	getQuickRedoData: (redoType?: string) => Record<string, string>;
}

const CharacterCreationContext = createContext<
	CharacterCreationContextType | undefined
>(undefined);

export const CharacterCreationProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const characterCreation = useCharacterCreation();

	return (
		<CharacterCreationContext.Provider value={characterCreation}>
			{children}
		</CharacterCreationContext.Provider>
	);
};

export const useCharacterCreationContext = () => {
	const context = useContext(CharacterCreationContext);
	if (context === undefined) {
		throw new Error(
			"useCharacterCreationContext must be used within a CharacterCreationProvider"
		);
	}
	return context;
};
