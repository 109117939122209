"use client";

import {
	CharacterProcessStep,
	useCharacter,
	useCharacterCreationContext,
	useJob,
} from "@/contexts";
import { useCallback, useEffect, useRef, useState } from "react";
import { CharacterCreationStep } from "./useCharacterCreation";
import axios from "axios";
import { Character } from "@/app/character";
import { useWebSocketContext } from "@/contexts/WebSocketProvider";
import { makeToastError } from "@/utils";
import { useAuth } from "@/components/Auth";

export const useCharacterRedo = () => {
	const { character, setCharacter } = useCharacter();
	const charactersRef = useRef<Character[]>([]);
	const [currentCharacterIndex, setCurrentCharacterIndex] = useState(0);
	const [charactersLength, setCharactersLength] = useState(1);
	const { setStep, setProcessStep, step, setProgress, updateQuickRedo } =
		useCharacterCreationContext();
	const {
		status: connectionStatus,
		lastMessage,
		setJobId,
		close,
	} = useWebSocketContext();
	const [currentJobId, setCurrentJobId] = useState<string | null>(null);
	const processedJobsRef = useRef<Set<string>>(new Set());
	const { currentJobId: initialJobId, jobStatus: initialJobStatus } = useJob();
	const isInitialJobHandled = useRef(false);
	const { refreshUserData } = useAuth();
	const handleJobStatus = useCallback(
		(data: any) => {
			if (isInitialJobHandled.current) return;
			if (data.type === "redo_modeling") {
				if (data.status === "failed") {
					makeToastError({
						message: `Error: ${data.result.error}`,
					});
					setStep(CharacterCreationStep.previewMesh);
					// setCurrentJobId(null);
					close();
					const character_urls = data.result.character_urls;
					const textures_urls = data.result.textures_urls;
					if (character_urls && textures_urls) {
						charactersRef.current = character_urls.map(
							(url: string, index: number) => {
								return {
									iterationNb: index,
									object: character_urls[index],
									objectTexture: textures_urls[index],
									id: data.result.id,
								};
							}
						);
					}
					setCharactersLength(charactersRef.current.length);
					setCurrentCharacterIndex(charactersRef.current.length - 1);
					// setCharacter(charactersRef.current[charactersRef.current.length - 1]);
				} else if (data.status === "completed") {
					if (data.result.final_obj_url && data.result.final_texture_url) {
						// const newCharacter: Character = {
						// 	iterationNb: charactersRef.current.length,
						// 	object: data.result.final_obj_url,
						// 	objectTexture: data.result.final_texture_url,
						// 	id: data.result.id,
						// };
						console.log("Character Redo Job");
						const character_urls = data.result.character_urls;
						const textures_urls = data.result.textures_urls;
						if (character_urls && textures_urls) {
							charactersRef.current = character_urls.map(
								(url: string, index: number) => {
									return {
										iterationNb: index,
										object: character_urls[index],
										objectTexture: textures_urls[index],
										id: data.result.id,
									};
								}
							);
						}
						// charactersRef.current.push(newCharacter);
						// setRedoCount(2 - data.result.iteration);
						// if (data.result.redo_type === "bad_morphology") {
						// 	setRedoCount((prevCount) => prevCount - 1);
						// }
						console.log("charactersRef.current:", charactersRef.current);
						setCurrentCharacterIndex(charactersRef.current.length - 1);
						setCharactersLength(charactersRef.current.length);
						// setCharacter(newCharacter);
						// setStep(CharacterCreationStep.previewMesh);
						updateQuickRedo(
							data.result.final_obj_url,
							data.result.final_texture_url,
							data.result.render_urls,
							data.result.id,
							data.result.iteration
						);
						// setCurrentJobId(null);
						setProgress(0);
						close();
					}
					// setCurrentJobId(null);
					processedJobsRef.current.add(data.job_id);
				} else if (data.status === "processing") {
					setProgress(data.progress || 0);
					setStep(CharacterCreationStep.processing);
					setProcessStep(CharacterProcessStep.redo);
				}
			}
			isInitialJobHandled.current = true;
		},
		[close, setProcessStep, setProgress, setStep, updateQuickRedo]
	);

	useEffect(() => {
		if (
			initialJobId &&
			initialJobStatus &&
			initialJobStatus.data.type === "redo_modeling"
		) {
			if (initialJobStatus.data.status === "processing") {
				setCurrentJobId(initialJobId);
			}
			handleJobStatus(initialJobStatus.data);
		}
	}, [handleJobStatus, initialJobId, initialJobStatus]);

	useEffect(() => {
		if (currentJobId) {
			console.log("Setting job id in websocket:", currentJobId);
			setJobId(currentJobId);
		}
	}, [currentJobId, setJobId]);

	const addToCharacters = useCallback(
		(object: string, texture: string) => {
			console.log("Adding to characters", object, texture);
			const newCharacter: Character = {
				...character,
				iterationNb: charactersRef.current.length,
				object,
				objectTexture: texture,
				id: character?.id || "",
			};
			charactersRef.current.push(newCharacter);
			setCharactersLength(charactersRef.current.length);
			setCharacter(newCharacter);
			setCurrentCharacterIndex(charactersRef.current.length - 1);
			console.log("charactersRef.current:", charactersRef.current);
		},
		[character, setCharacter]
	);

	const removeFromCharacters = useCallback(() => {
		if (charactersRef.current.length > 0) {
			const currentCharacterId =
				charactersRef.current[charactersRef.current.length - 1].id;
			charactersRef.current.pop();
			setCharactersLength(charactersRef.current.length);
			setCharacter(charactersRef.current[charactersRef.current.length - 1]);
			setCharacter((prev) => {
				return {
					...prev,
					id: currentCharacterId,
				};
			});
			setCurrentCharacterIndex(charactersRef.current.length - 1);
		}
	}, [setCharacter]);

	// useEffect(() => {
	// 	if (
	// 		character &&
	// 		character.object &&
	// 		character.objectTexture &&
	// 		!charactersRef.current.some((c) => c.id === character.id)
	// 	) {
	// 		charactersRef.current.push(character);
	// 		setCharactersLength(charactersRef.current.length);
	// 	}
	// }, [character]);

	const redoCharacter = useCallback(
		async (redoType: string) => {
			if (
				(redoType === "bad_morphology" && character?.id) ||
				(redoType !== "bad_morphology" && character?.id)
			) {
				console.log("Redoing character");
				console.log("characterRef.current:", charactersRef.current);
				setStep(CharacterCreationStep.processing);
				try {
					const formData = new FormData();
					formData.append("character", character.id);
					formData.append(
						"iteration_nb",
						charactersRef.current.length.toString()
					);
					formData.append("redo_type", redoType);
					console.log(
						"charactersRef.current.length.toString():",
						charactersRef.current.length.toString()
					);

					const response = await axios.post(
						"/api/v1/redo-character-modeling",
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);
					console.log(response.data);

					if (response.data.job_id) {
						setCurrentJobId(response.data.job_id);
						setProcessStep(CharacterProcessStep.redo);
						localStorage.setItem("job_id", response.data.job_id);
					} else {
						throw new Error("No job_id returned");
					}
				} catch (error) {
					console.error("Error redoing character:", error);
					setStep(CharacterCreationStep.previewMesh);
				}
			}
		},
		[character?.id, setProcessStep, setStep]
	);

	useEffect(() => {
		if (
			lastMessage &&
			lastMessage.type === "job_completed" &&
			lastMessage.data.type === "redo_modeling" &&
			lastMessage.data.status === "completed"
			// !processedJobsRef.current.has(lastMessage.data.job_id)
		) {
			const data = lastMessage.data;
			console.log("Processing new Character Redo Job result:", data.result);

			if (data.result.final_obj_url && data.result.final_texture_url) {
				const character_urls = data.result.character_urls;
				const textures_urls = data.result.textures_urls;
				if (character_urls && textures_urls) {
					charactersRef.current = character_urls.map(
						(url: string, index: number) => {
							return {
								iterationNb: index,
								object: character_urls[index],
								objectTexture: textures_urls[index],
								id: data.result.id,
							};
						}
					);
				}
				// const newCharacter: Character = {
				// 	...character,
				// 	iterationNb: charactersRef.current.length,
				// 	object: data.result.final_obj_url,
				// 	objectTexture: data.result.final_texture_url,
				// 	id: data.result.id,
				// };
				// charactersRef.current.push(newCharacter);
				if (data.result.redo_type === "bad_morphology") {
					refreshUserData(true);
				}
				setCurrentCharacterIndex(charactersRef.current.length - 1);
				setCharactersLength(charactersRef.current.length);
				updateQuickRedo(
					data.result.final_obj_url,
					data.result.final_texture_url,
					data.result.render_urls,
					data.result.id,
					data.result.iteration
				);

				// setCharacter(newCharacter);
				// setStep(CharacterCreationStep.previewMesh);
				setCurrentJobId(null);
				setProgress(0);
				close();
			}
			setCurrentJobId(null);
			processedJobsRef.current.add(data.job_id);
		} else if (
			lastMessage &&
			lastMessage.type === "job_status" &&
			lastMessage.data.type === "redo_modeling" &&
			lastMessage.data.status === "processing"
		) {
			setProgress(lastMessage.data.progress || 0);
			setStep(CharacterCreationStep.processing);
		} else if (
			lastMessage &&
			lastMessage.type === "job_status" &&
			lastMessage.data.type === "redo_modeling" &&
			lastMessage.data.status === "failed"
		) {
			makeToastError({ message: `Error: ${lastMessage.data.result.error}` });
			console.error("Job failed:", lastMessage.data.result.error);
			setStep(CharacterCreationStep.previewMesh);
			setCurrentJobId(null);
			close();
		}
	}, [
		close,
		lastMessage,
		refreshUserData,
		setProgress,
		setStep,
		updateQuickRedo,
	]);

	const previousCharacter = useCallback(() => {
		if (currentCharacterIndex > 0) {
			const newIndex = currentCharacterIndex - 1;
			setCharacter(charactersRef.current[newIndex]);
			setCurrentCharacterIndex(newIndex);
		}
	}, [currentCharacterIndex, setCharacter]);

	const nextCharacter = useCallback(() => {
		if (currentCharacterIndex < charactersRef.current.length - 1) {
			const newIndex = currentCharacterIndex + 1;
			setCharacter(charactersRef.current[newIndex]);
			setCurrentCharacterIndex(newIndex);
		}
	}, [currentCharacterIndex, setCharacter]);

	const clearCharacters = useCallback(() => {
		charactersRef.current = [];
		setCharactersLength(0);
		setCurrentCharacterIndex(0);
	}, []);

	return {
		redoCharacter,
		charactersLength,
		previousCharacter,
		nextCharacter,
		currentCharacterIndex,
		addToCharacters,
		removeFromCharacters,
		clearCharacters,
	};
};
