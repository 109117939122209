"use client";
import { useState, useCallback, useEffect, useRef } from "react";
import { useCharacter, useCharacterCreationContext, useJob } from "@/contexts";
import { useWebSocketContext } from "@/contexts/WebSocketProvider";
import axios, { AxiosError } from "axios";
import { Character } from "@/app/character";
import { makeToastError } from "@/utils";
import { CharacterCreationStep } from "./useCharacterCreation";
import { useAuth } from "@/components/Auth";

export enum AnimationCreationStep {
	Initial,
	Upload,
	Processing,
	Preview,
}

export type VideoSource = File | string;

export const useAnimationCreation = () => {
	const [step, setStep] = useState<AnimationCreationStep>(
		AnimationCreationStep.Initial
	);
	const [videoSource, setVideoSource] = useState<VideoSource | null>(null);
	const [progress, setProgress] = useState<number>(0);
	const { character, setCharacter } = useCharacter();
	const { setStep: setCharacterCreationStep } = useCharacterCreationContext();
	const {
		status: connectionStatus,
		lastMessage,
		setJobId,
		close,
	} = useWebSocketContext();

	const { currentJobId: initialJobId, jobStatus: initialJobStatus } = useJob();

	const [currentJobId, setCurrentJobId] = useState<string | null>(null);
	const processedJobsRef = useRef<Set<string>>(new Set());

	const [shouldRefreshCredits, setShouldRefreshCredits] = useState(false);

	const { refreshUserData } = useAuth();

	const previewVideoUpload = useCallback((file: File) => {
		setVideoSource(file);
		setStep(AnimationCreationStep.Upload);
	}, []);

	const cancelCreation = useCallback(() => {
		setVideoSource(null);
		setStep(AnimationCreationStep.Initial);
		setCharacterCreationStep(CharacterCreationStep.previewRigging);
		setCurrentJobId(null);
		setCharacter((prev: Character | null) => {
			if (prev === null) return null;
			return {
				...prev,
				animatedAudio: undefined,
				animatedCharacter: undefined,
			};
		});
		close();
	}, [setCharacterCreationStep, setCharacter, close]);

	useEffect(() => {
		if (shouldRefreshCredits) {
			refreshUserData(true);
			setShouldRefreshCredits(false);
		}
	}, [shouldRefreshCredits, refreshUserData]);

	const startMotionGeneration = useCallback(async () => {
		setStep(AnimationCreationStep.Processing);
		try {
			const formData = new FormData();
			if (!videoSource) {
				throw new Error("No file to upload");
			}
			if (!(videoSource instanceof File)) {
				throw new Error("Video source is not a file");
			}
			formData.append("file", videoSource, videoSource.name);
			if (character && character.id) {
				formData.append("character", character.id);
			} else {
				throw new Error("No character ID available");
			}

			const response = await axios.post(
				"/api/v1/character-animation",
				formData,
				{
					headers: { "Content-Type": "multipart/form-data" },
				}
			);

			if (response.data.job_id) {
				setCurrentJobId(response.data.job_id);
				localStorage.setItem("job_id", response.data.job_id);
			} else {
				throw new Error("No job ID received from server");
			}
		} catch (error) {
			console.error("Error uploading video:", error);
			if (error instanceof AxiosError && error.status === 403) {
				makeToastError({ message: `Error: ${error.response?.data.detail}` });
			}
			setStep(AnimationCreationStep.Upload);
		}
	}, [character, videoSource]);

	useEffect(() => {
		if (currentJobId) {
			setJobId(currentJobId);
		}
	}, [currentJobId, setJobId]);

	const handleJobStatus = useCallback(
		(data: any) => {
			if (data.type === "animation") {
				if (data.result.video_url) setVideoSource(data.result.video_url);
				if (data.status === "failed") {
					makeToastError({
						message: `Error: ${data.result.error}`,
					});
					setStep(AnimationCreationStep.Initial);
					setCurrentJobId(null);
					close();
					setCharacter((prev) => ({
						...prev,
						animatedCharacter: undefined,
						animatedAudio: undefined,
						animationTemplateId: undefined,
						jumpingCharacter: data.result.jumping_character_url,
						objectTexture: data.result.final_texture_url,
						riggedObject: data.result.rigged_character_url,
						id: data.result.id,
						isReadyForAnimation: true,
					}));
					setProgress(0);
					setCharacterCreationStep(CharacterCreationStep.previewRigging);
				} else if (data.status === "completed") {
					if (data.result.animated_obj_url) {
						const newCharacter: Character = {
							animatedCharacter: data.result.animated_obj_url,
							animatedAudio: data.result.animated_audio_url || undefined,
							animationTemplateId: undefined,
							id: data.result.id,
							objectTexture: data.result.final_texture_url,
							isReadyForAnimation: true,
							jumpingCharacter: data.result.jumping_character_url,
							riggedObject: data.result.rigged_character_url,
						};
						setCharacter((prev) => ({
							...prev,
							...newCharacter,
						}));
						setStep(AnimationCreationStep.Preview);
						setCharacterCreationStep(CharacterCreationStep.motion);
						setProgress(0);
						close();
					}
					setCurrentJobId(null);
				} else if (data.status === "processing") {
					setProgress(data.progress || 0);
					setStep(AnimationCreationStep.Processing);
					const newCharacter: Character = {
						animatedCharacter: undefined,
						animatedAudio: undefined,
						animationTemplateId: undefined,
						id: data.result.id,
						objectTexture: data.result.final_texture_url,
						jumpingCharacter: data.result.jumping_character_url,
						riggedObject: data.result.rigged_character_url,
						isReadyForAnimation: true,
					};
					setCharacterCreationStep(CharacterCreationStep.previewRigging);
					setCharacter((prev) => ({
						...prev,
						...newCharacter,
					}));
				}
			}
		},
		[close, setCharacter, setCharacterCreationStep]
	);

	useEffect(() => {
		if (
			initialJobId &&
			initialJobStatus &&
			initialJobStatus.data.type === "animation"
		) {
			setCurrentJobId(initialJobId);
			handleJobStatus(initialJobStatus.data);
		}
	}, [handleJobStatus, initialJobId, initialJobStatus]);

	useEffect(() => {
		if (
			lastMessage &&
			lastMessage.type === "job_status" &&
			lastMessage.data.type === "animation"
			// !processedJobsRef.current.has(lastMessage.data.job_id)
		) {
			const data = lastMessage.data;
			if (data.status === "failed") {
				makeToastError({ message: `Error: ${data.result.error}` });
				console.error("Job failed:", data.result.error);
				setStep(AnimationCreationStep.Upload);
				setCurrentJobId(null);
				processedJobsRef.current.add(data.job_id);
				setProgress(0);
				close();
			} else if (data.status === "processing") {
				setProgress(data.progress || 0);
			}
		} else if (
			lastMessage &&
			lastMessage.type === "job_completed" &&
			lastMessage.data.type === "animation"
			// !processedJobsRef.current.has(lastMessage.data.job_id)
		) {
			const data = lastMessage.data;
			console.log("Animation Job status:", data.status);

			if (data.status === "completed") {
				if (data.result.animated_obj_url) {
					setCharacter((prev: Character | null) => {
						if (prev === null) return null;
						return {
							...prev,
							animatedCharacter: data.result.animated_obj_url,
							animatedAudio: data.result.animated_audio_url || undefined,
							animationTemplateId: undefined,
						};
					});
					setCharacterCreationStep(CharacterCreationStep.motion);
					setStep(AnimationCreationStep.Preview);
					setShouldRefreshCredits(true);
				}
				setCurrentJobId(null);
				processedJobsRef.current.add(data.job_id);
				setProgress(0);
				close();
			}
		}
	}, [close, lastMessage, setCharacter, setCharacterCreationStep]);

	return {
		step,
		setStep,
		videoSource,
		setVideoSource,
		progress,
		previewVideoUpload,
		cancelCreation,
		startMotionGeneration,
	};
};
